import { template as template_6fa1e6df1b01486e9409e8836a56157f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_6fa1e6df1b01486e9409e8836a56157f(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
