import { template as template_62e2b0b5848f4f1289ee231513e29b50 } from "@ember/template-compiler";
const FKText = template_62e2b0b5848f4f1289ee231513e29b50(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
