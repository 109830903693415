import { template as template_a5f2cbc3c39d48e789f701e531f32e43 } from "@ember/template-compiler";
const WelcomeHeader = template_a5f2cbc3c39d48e789f701e531f32e43(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
